<template>
  <div class="subpage-container">
    <div class="slick-container fullscreen">
      <VueSlickCarousel
        ref="slick"
        :infinite="false"
        :arrows="false"
        @afterChange="onAfterChange"
      >
        <div class="slide-item">
          <div class="slide-container--text">
            <div class="text-container">
              <h1>遊牧與定居音樂文化對比表</h1>
              <p>
                中亞的遊牧與定居的音樂文化之間的對比表，<br />令觀眾更直觀感受這兩種音樂文化的一些差異。
              </p>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="slide-container--text">
            <div class="text-container">
              <h1>遊牧與定居音樂文化對比</h1>
              <p>
                遊牧傳統 (nomadic)
                的樂器通常沒有品（fret），具2-3條發聲弦。彈奏時通常直接用手指彈撥。獨奏為主，演奏時節奏/鼓並不重要（鼓通常在薩滿儀式才見到）。通常沒有性別的分隔，不同性別的樂師和觀眾常聚在一起。
              </p>
              <p>
                定居傳統 (sedentary)
                的樂器通常有品（fret），有些樂器會有共鳴弦。有的樂器會用金屬或撥子彈撥。以小組合奏為主，鼓和節奏十分重要，是小組合奏的核心。傳統上性別分隔比較嚴格，男女演奏者會分開演出，觀眾也分男女——女觀眾看女演奏者演出，男觀眾看男演奏者演出。
              </p>
            </div>
          </div>
        </div>
        <div class="slide-item" v-for="i in 7" :key="`compare-${i - 1}`">
          <img
            :src="
              require(`@/assets/images/gallery2/compare/compare-${i - 1}.jpg`)
            "
            class="slide-image"
          />
        </div>
      </VueSlickCarousel>
      <div
        :class="{
          'btn-controls': true,
        }"
      >
        <div
          v-if="this.currentSlide != 0"
          :class="{ 'btn-prev': true }"
          @click="prev"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
        <div
          v-if="this.currentSlide < 8"
          :class="{ 'btn-next': true }"
          @click="next"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  data() {
    return {
      currentSlide: 0,
      myMusic: null,
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    this.$store.commit("updateCurrentSection", 1);

    this.myMusic = new Howl({
      src: ["../../../music/gallery2/table.mp3"],
      loop: true,
    });

    this.myMusic.once("load", () => {
      this.myMusic.stop();
      this.myMusic.play();
    });
  },
  destroyed() {
    this.myMusic?.once("fade", () => {
      this.myMusic.unload();
    });
    this.myMusic?.fade(this.myMusic.volume(), 0, 1000);
  },
  methods: {
    onAfterChange(slideIndex) {
      this.currentSlide = slideIndex;
    },
    prev() {
      this.$refs.slick.prev();
    },
    next() {
      this.$refs.slick.next();
    },
  },
};
</script>

<style lang="scss"></style>
